import React from "react"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <div className="error-page">
    <SEO title="404: Not found" />
    <h2>NOT FOUND</h2>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </div>
)

export default NotFoundPage
